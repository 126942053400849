
import { defineComponent } from "vue";
import SettlementEntityHistoricalInfomation from "@/components/page/kyc/settlement-entity/HistoricalInformation.vue";

export default defineComponent({
  name: "settlement-entity-legal-entity-historical-information",
  components: {
    SettlementEntityHistoricalInfomation,
  },
  setup() {
    return {};
  },
});
